<template>
  <v-container id="explorer" fluid tag="section">
    <v-row class="justify-center">
      <v-col cols="12" sm="10">
        <page-top-title>
          <template v-slot:toptitle-icon>mdi-book-open</template>
          <template v-slot:toptitle-text>{{ $t("EPG") }}</template>
          <template v-slot:toptitle-button>Saber más</template>
        </page-top-title>
      </v-col>
    </v-row>
    <reactive-base
      :app="app"
      :key="searchBaseKey"
      :url="url"
      :credentials="credentials"
      :type="type"
    >
      <!-- RESULTADOS -->
      <v-row justify="center">
        <v-col cols="12" md="7">
          <v-card class="pa-0" rounded="lg">
            <v-card-title class="solid-heading rounded-lg py-0 px-10">
              <v-icon large color="white" class="mr-4 mt-1">mdi-magnify</v-icon>
              <data-search
                :innerClass="{ input: 'reactive-search-input' }"
                :showIcon="false"
                :URLParams="true"
                :showClear="false"
                :placeholder="searchPlaceholder"
                filterLabel="Name"
                componentId="SearchSensor"
                :autosuggest="true"
                :dataField="datafields"
                class=" grow"
              />
            </v-card-title>
            <v-row class="px-8 search-bar d-flex mt-4">
              <like-radio-buttons
                @buttonSelected="buttonSelected"
                :activeButtonKey="app"
                class="buttons d-flex flex-wrap mb-2"
                :buttons="[
                  { text: 'Drugs', icon: 'pill', key: 'kb-drugs' },
                  { text: 'Genes', icon: 'dna', key: 'kb-genes' }
                ]"
              />
            </v-row>

            <v-row class="px-8">
              <v-list class="searchbase-results" rounded>
                <v-list-item-group color="primary">
                  <selected-filters
                    clearAllLabel="Clear"
                    :showClearAll="true"
                  />
                  <ReactiveList
                    componentId="result"
                    dataField="Critic_Score"
                    class="results"
                    :defaultQuery="defaultQuery"
                    :innerClass="{ resultStats: 'result-stat' }"
                    pagination
                    :size="8"
                    :URLParams="true"
                    :showResultStats="false"
                    :react="reactiveListReact"
                  >
                    <div slot="renderItem" slot-scope="{ item }">
                      <searchbase-drug-result-item
                        v-if="app == 'kb-drugs'"
                        :drug="item"
                      />
                      <searchbase-gene-result-item v-else :gene="item" />
                    </div>
                  </ReactiveList>
                </v-list-item-group>
              </v-list>
            </v-row>
          </v-card>
        </v-col>

        <!-- Filtros -->
        <v-col cols="12" md="3" class="sticky">
          <v-card v-if="app == 'kb-drugs'" class="rounded-lg pa-0 z-index-99">
            <v-card-title class="solid-heading rounded-lg mb-4 py-3">
              <h3 class="white--text font-weight-regular">
                <v-icon color="white">mdi-filter</v-icon> Filters
              </h3>
            </v-card-title>
            <v-card-text>
              <data-search
                :innerClass="{ input: 'reactive-search-input' }"
                :showIcon="false"
                :URLParams="true"
                title="Diseases"
                filterLabel="Diseases"
                :showClear="false"
                placeholder="Search by Disease"
                componentId="DrugSearchDisesasesSensor"
                :autosuggest="true"
                :dataField="drugDiseaseDatafields"
                class="mr-4 mb-4 grow z-index"
              />
            </v-card-text>
          </v-card>
          <v-card v-if="app == 'kb-genes'" class="pa-0">
            <v-card-title class="solid-heading rounded-lg mb-4 py-3">
              <h3 class="white--text font-weight-regular">
                <v-icon color="white">mdi-virus</v-icon> Diseases
              </h3>
            </v-card-title>
            <v-card-text>
              <data-search
                :innerClass="{ input: 'reactive-search-input' }"
                :showIcon="false"
                :URLParams="true"
                filterLabel="Diseases"
                :showClear="false"
                placeholder="Search by disease"
                componentId="GeneSearchDisesasesSensor"
                :autosuggest="true"
                :dataField="geneDiseaseDatafields"
                class="mr-4 mb-4 grow"
              />
            </v-card-text>
          </v-card>
          <base-material-card class="pa-0">
            <v-card-title class="solid-heading rounded-lg mb-4 py-3">
              <h3 class="white--text font-weight-regular">Summaries</h3>
            </v-card-title>
            <v-card-text>
              <v-list-item v-if="app == 'kb-drugs'">
                <v-list-item-content>
                  <v-list-item-title
                    ><a @click="$router.push({ name: 'ApdxDrugClass' })"
                      >Drugs classification</a
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="app == 'kb-genes'">
                <v-list-item-content>
                  <v-list-item-title
                    ><a @click="$router.push({ name: 'ApdxDiseasesGenes' })"
                      >Diseases / Genes</a
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
      <!-- resultados -->
    </reactive-base>
  </v-container>
</template>

<script>
import { CRED, TYPE, URL } from "@/helpers/constants";
import LikeRadioButtons from "@/components/mylogy/LikeRadioButtons";
import SearchbaseDrugResultItem from "@/components/mylogy/explorer/SearchbaseDrugResultItem";
import SearchbaseGeneResultItem from "@/components/mylogy/explorer/SearchbaseGeneResultItem";
import PageTopTitle from "@/components/mylogy/PageTopTitle";
export default {
  props: {
    app: {
      type: String,
      default: "kb-drugs"
    }
  },
  components: {
    LikeRadioButtons,
    SearchbaseDrugResultItem,
    SearchbaseGeneResultItem,
    PageTopTitle
  },
  data() {
    return {
      credentials: CRED,
      searchBaseKey: 1,
      type: TYPE,
      url: URL,
      search: null,
      geneResults: {},
      atcSelectorSelected: {
        atc1: "",
        atc2: "",
        atc3: "",
        atc4: ""
      }
    };
  },
  computed: {
    geneDiseaseDatafields() {
      return [
        `Dis_EN`,
        `Dis_EN.autosuggest`,
        `Dis_EN.delimiter`,
        `Dis_EN.lang`,
        `Dis_EN.synonyms`
      ];
    },
    drugDiseaseDatafields() {
      return [
        `DC_Ind_EN`,
        `DC_Ind_EN.autosuggest`,
        `DC_Ind_EN.delimiter`,
        `DC_Ind_EN.lang`,
        `DC_Ind_EN.synonyms`,
        `DC_Off_EN`,
        `DC_Off_EN.autosuggest`,
        `DC_Off_EN.delimiter`,
        `DC_Off_EN.lang`,
        `DC_Off_EN.synonyms`,
        `DC_Off_EN`
      ];
    },
    reactiveListReact() {
      if (this.app == "kb-drugs") {
        return {
          and: ["SearchSensor", "DrugSearchDisesasesSensor"]
        };
      } else {
        return { and: ["SearchSensor", "GeneSearchDisesasesSensor"] };
      }
    },
    searchPlaceholder() {
      if (this.app == "kb-drugs") {
        return "Search Drugs";
      } else {
        return "Search Genes";
      }
    },
    datafields() {
      if (this.app == "kb-drugs") {
        return [
          "Drug_Name_EN",
          "Drug_Name_EN.autosuggest",
          "Brandnames",
          "Brandnames.autosuggest"
        ];
      } else {
        return [
          "Alternative_Names",
          "Alternative_Names.autosuggest",
          "Alternative_Names.delimiter",
          "Alternative_Names.lang",
          "Alternative_Names.synonyms",
          "alternative_symbols",
          "alternative_symbols.autosuggest",
          "alternative_symbols.delimiter",
          "alternative_symbols.lang",
          "alternative_symbols.synonyms",
          "Symbol",
          "Symbol.autosuggest",
          "Symbol.delimiter",
          "Symbol.lang",
          "Symbol.synonyms",
          `Gene_Name_EN`,
          `Gene_Name_EN.autosuggest`,
          `Gene_Name_EN.delimiter`,
          `Gene_Name_EN.lang`,
          `Gene_Name_EN.synonyms`,
          "OMIM",
          "OMIM.autosuggest",
          "OMIM.delimiter",
          "OMIM.lang",
          "OMIM.synonyms",
          "Locus",
          "Locus.autosuggest",
          "Locus.delimiter",
          "Locus.lang",
          "Locus.synonyms"
        ];
      }
    }
  },
  mounted() {},
  methods: {
    defaultQuery() {
      if (this.app == "kb-drugs") {
        return {
          query: {
            term: { Has_EPG: true }
          },
          sort: {
            "Drug_Name_EN.keyword": { order: "asc" }
          }
        };
      } else {
        return {
          query: {
            term: { Has_EPG: true }
          },
          sort: {
            "Symbol.keyword": { order: "asc" }
          }
        };
      }
    },
    buttonSelected(button) {
      this.$router.push({ name: "Explorer", params: { app: button.key } });
      this.searchBaseKey++;
    }
  }
};
</script>

<style lang="scss">
.searchbase-results {
  width: 100%;
}
.search-bar .buttons button {
  flex-grow: 1;
  margin-right: 10px !important;
}

/* Paginacion pagination */
.results .css-1oof9tm {
  a {
    background: none;
    color: #4d2cb9;
  }
  :focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.css-flmbq0 input,
.filters input,
.filters button {
  border-color: #4d2cb9;
}
.filters .css-iifocb,
.css-iifocb {
  box-shadow: 0 0 20px -15px;
}

.filters ul,
.css-iifocb {
  padding: 10px !important;
  border-radius: 25px;
  li {
    margin-top: 1em;
    line-height: 1em;
    border-radius: 3em;
    transition: 0.6s;
  }
  li:hover {
    transition: 0.4s;
  }
  label::before {
    border-radius: 15px;
    height: 20px !important;
    min-width: 20px;
    width: 20px !important;
    border-width: 2px;
    border-color: #4d2cb9 !important;
    transition: 0.4s;
    outline: none !important;
  }
}
</style>
<style lang="scss" scoped></style>
